import React from 'react';
import { Link } from 'gatsby';
import Authors from '@components/BlogAuthors';
import Metadata from '@components/BlogMetadata';
import {
  PrismicAuthorFragmentFragment,
  PrismicBlogPageFragmentFragment,
} from '@utils/typings/prismic-types';
import * as styles from './styles.module.scss';

interface Props {
  blogPage: PrismicBlogPageFragmentFragment;
  authors: PrismicAuthorFragmentFragment[];
}

export default function BlogFeedFeaturedCard({ blogPage, authors }: Props) {
  const hasAuthors = blogPage.data.authors[0].author.id !== null;
  const pageAuthors = hasAuthors
    ? blogPage.data.authors.map((a) =>
        authors.find((author) => author.prismicId === a.author.id),
      )
    : [];

  return (
    <Link to={blogPage.url} className={styles.card}>
      <img
        src={blogPage.data.metadata_image.url}
        alt={blogPage.data.metadata_image.alt}
        className={styles.image}
      />
      <div className={styles.text}>
        <div className={styles.metadata}>
          <Metadata blogPage={blogPage} />
        </div>
        <h1 className={styles.title}>{blogPage.data.title.text}</h1>
        <p className={styles.subtitle}>{blogPage.data.subtitle.text}</p>
        <div className={styles.authors}>
          <Authors authors={pageAuthors} />
        </div>
      </div>
    </Link>
  );
}
