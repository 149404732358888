import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import BlogFeedContent from '@components/BlogFeedContent';
import BlogFeedLayout from '@components/BlogFeedLayout';
import { useAllPrismicAuthor } from '@utils/hooks/useAllPrismicAuthor';
import { useAllPrismicBlogPage } from '@utils/hooks/useAllPrismicBlogPage';
import linkResolver from '@utils/linkResolver';
import { BlogFeedTemplateQuery } from '@typings/prismic-types';

type Props = PageProps<BlogFeedTemplateQuery>;

export const BlogFeedTemplate = ({ data }: Props) => {
  const allPrismicAuthor = useAllPrismicAuthor();
  const allPrismicBlogPage = useAllPrismicBlogPage();

  if (!data) return null;

  return (
    <BlogFeedLayout
      blogFeed={data.prismicBlogFeed}
      banner={data.prismicBanner}
      nav={data.prismicNav}
      footer={data.prismicFooter}
    >
      <BlogFeedContent
        blogFeed={data.prismicBlogFeed}
        blogPages={allPrismicBlogPage}
        authors={allPrismicAuthor}
      />
    </BlogFeedLayout>
  );
};

export const query = graphql`
  query BlogFeedTemplate($lang: String) {
    prismicBlogFeed {
      ...PrismicBlogFeedFragment
    }
    prismicNav(lang: { eq: $lang }) {
      ...NavFragment
    }
    prismicFooter(lang: { eq: $lang }) {
      ...FooterFragment
    }
    prismicBanner {
      ...PrismicBannerFragment
    }
  }
`;

export default withPrismicPreview(BlogFeedTemplate, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
]);
