import React from 'react';
import { graphql } from 'gatsby';
import BlogFeedFeatured from '@components/BlogFeedFeatured';
import BlogFeedHeader from '@components/BlogFeedHeader';
import BlogFeedHeroCard from '@components/BlogFeedHeroCard';
import BlogFeedListCard from '@components/BlogFeedListCard';
import {
  PrismicBlogFeedFragmentFragment,
  PrismicBlogPageFragmentFragment,
  PrismicAuthorFragmentFragment,
} from '@typings/prismic-types';
import * as styles from './styles.module.scss';

type Props = {
  blogFeed: PrismicBlogFeedFragmentFragment;
  blogPages?: PrismicBlogPageFragmentFragment[];
  authors: PrismicAuthorFragmentFragment[];
};

export default function BlogFeedContent({
  blogFeed,
  blogPages,
  authors,
}: Props) {
  const featuredIds = [
    blogFeed.data.featured_article_1.id,
    blogFeed.data.featured_article_2.id,
  ].filter(Boolean);
  const featuredPages = featuredIds.map((id) =>
    blogPages.find((page) => page.prismicId === id),
  );
  const otherPages = blogPages.filter(
    (page) => !featuredIds.includes(page.prismicId),
  );

  return (
    <div>
      <div className={styles.header}>
        <BlogFeedHeader />
      </div>
      <div className={styles.firstPost}>
        <BlogFeedHeroCard blogPage={blogPages[0]} authors={authors} />
      </div>
      {!!featuredPages.length && (
        <div className={styles.featured}>
          <BlogFeedFeatured pages={featuredPages} authors={authors} />
        </div>
      )}
      <div className={styles.list}>
        {otherPages.slice(1).map((page) => (
          <BlogFeedListCard key={page.id} blogPage={page} authors={authors} />
        ))}
      </div>
    </div>
  );
}

export const query = graphql`
  fragment PrismicBlogFeedFragment on PrismicBlogFeed {
    data {
      metadata_title {
        text
      }
      metadata_image {
        alt
        url
      }
      metadata_description {
        text
      }
      featured_article_2 {
        id
      }
      featured_article_1 {
        id
      }
    }
  }
`;
