import clsx from 'clsx';
import React from 'react';
import { Link } from 'gatsby';
import Authors from '@components/BlogAuthors';
import { publishDate } from '@utils/publishDate';
import { readingTime } from '@utils/readingTime';
import {
  PrismicAuthorFragmentFragment,
  PrismicBlogPageFragmentFragment,
} from '@utils/typings/prismic-types';
import * as styles from './styles.module.scss';

interface Props {
  blogPage: PrismicBlogPageFragmentFragment;
  authors: PrismicAuthorFragmentFragment[];
}

export default function BlogFeedListCard({ blogPage, authors }: Props) {
  const hasAuthors = blogPage.data.authors[0].author.id !== null;
  const pageAuthors = hasAuthors
    ? blogPage.data.authors.map((a) =>
        authors.find((author) => author.prismicId === a.author.id),
      )
    : [];

  return (
    <Link to={blogPage.url} className={styles.card}>
      <div className={styles.metadata}>
        <Metadata blogPage={blogPage} />
      </div>
      <h1 className={clsx(styles.title, 'text-3xl md:text-5xl')}>
        {blogPage.data.title.text}
      </h1>
      <div className={styles.authors}>
        <Authors authors={pageAuthors} />
      </div>
      <img
        src={blogPage.data.metadata_image.url}
        alt={blogPage.data.metadata_image.alt}
        className={styles.image}
      />
      <p className={styles.subtitle}>{blogPage.data.subtitle.text}</p>
    </Link>
  );
}

interface MetadataProps {
  blogPage: PrismicBlogPageFragmentFragment;
}
function Metadata({ blogPage }: MetadataProps) {
  return (
    <div>
      <div>
        <span className={styles.published}>{publishDate(blogPage)}</span>
        <span className={styles.readTime}>{readingTime(blogPage)}</span>
      </div>
      <div className={styles.tags}>
        {blogPage.tags.map((tag) => (
          <span key={tag} className={styles.tag}>
            {tag}
          </span>
        ))}
      </div>
    </div>
  );
}
