import React from 'react';
import { Link } from 'gatsby';
import * as styles from './styles.module.scss';
import { SubscribeForm } from '@components/SubscribeForm';


export default function BlogFeedHeader() {
  return (
    <div className={styles.header}>
      <Link to="/blog">
        <h5 className={styles.link}>Pry Blog</h5>
      </Link>
      <SubscribeForm />
    </div>
  );
}
