import React from 'react';
import { Helmet } from 'react-helmet';
import Banner from '@components/Banner';
import Footer from '@components/Footer';
import Nav from '@components/Nav';
import Seo from '@components/Seo';
import {
  NavFragmentFragment,
  FooterFragmentFragment,
  PrismicBlogFeedFragmentFragment,
  PrismicBannerFragmentFragment,
} from '@typings/prismic-types';
import * as styles from './styles.module.scss';

type Props = {
  blogFeed: PrismicBlogFeedFragmentFragment;
  banner: PrismicBannerFragmentFragment;
  nav: NavFragmentFragment;
  footer: FooterFragmentFragment;
  children: React.ReactNode;
};

export default function BlogFeedLayout({
  blogFeed,
  banner,
  nav,
  footer,
  children,
}: Props) {
  return (
    <div className="typography">
      <Banner banner={banner} />
      <Seo
        title={blogFeed.data.metadata_title.text || 'Blog | Pry.co'}
        description={
          blogFeed.data.metadata_description.text || 'Latest updates from Pry'
        }
        image={blogFeed.data.metadata_image.url}
      />
      <Helmet>
        <link rel="canonical" href={'https://pry.co/blog'} />
      </Helmet>
      <Nav nav={nav} />
      <main className={styles.main}>{children}</main>
      <Footer footer={footer} />
    </div>
  );
}
