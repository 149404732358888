// extracted by mini-css-extract-plugin
export var strike = "styles-module--strike--XDHE+";
export var card = "styles-module--card--ozG5v";
export var title = "styles-module--title--r7IzT";
export var metadata = "styles-module--metadata--B5rq3";
export var published = "styles-module--published--C34sl";
export var readTime = "styles-module--read-time--4-r2y";
export var tags = "styles-module--tags--Xp6gp";
export var tag = "styles-module--tag--7Zf7+";
export var authors = "styles-module--authors--XMj8g";
export var image = "styles-module--image--FM8cO";
export var subtitle = "styles-module--subtitle--XxCFB";