import React from 'react';
import BlogFeedFeaturedCard from '@components/BlogFeedFeaturedCard';
import {
  PrismicBlogPageFragmentFragment,
  PrismicAuthorFragmentFragment,
} from '@typings/prismic-types';
import * as styles from './styles.module.scss';

type Props = {
  pages?: PrismicBlogPageFragmentFragment[];
  authors: PrismicAuthorFragmentFragment[];
};

export default function BlogFeedFeatured({ pages, authors }: Props) {
  return (
    <div className={styles.featured}>
      <div className={styles.background} />
      <section className={styles.main}>
        <h3 className={styles.title}>Featured</h3>
        <div className={styles.grid}>
          {pages.map((page) => (
            <BlogFeedFeaturedCard
              key={page.id}
              blogPage={page}
              authors={authors}
            />
          ))}
        </div>
      </section>
    </div>
  );
}
